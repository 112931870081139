


































































import { Component, Prop, Vue } from "vue-property-decorator";

import ExternalTestCard from "@/ab-tests/components/ExternalTestCard.vue";
import ExternalFinishConfirmDialog from "@/ab-tests/components/ExternalFinishConfirmDialog.vue";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { CurrentUserModel } from "@/shared/models";

@Component({ components: { ExternalTestCard, ExternalFinishConfirmDialog } })
export default class ExternalTestList extends Vue {
  @Prop({ default: "" }) search!: string;
  @Prop({ default: false }) isShowOnlyMy!: string;

  isConfirmationDialogVisible = false;
  finishingTest: ExternalTestModel | null = null;

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get sortedTests(): Record<ConfigsStatus, Array<ExternalTestModel>> {
    return Object.entries(
      this.$store.state.externalTestStore.externalTests as Record<
        ConfigsStatus,
        Array<ExternalTestModel>
      >
    ).reduce(
      (
        res: Record<ConfigsStatus, Array<ExternalTestModel>>,
        [status, tests]: [string, Array<ExternalTestModel>]
      ) => {
        const filteredConfigs = tests.filter(
          (test: ExternalTestModel) =>
            test.name?.toLowerCase().includes(this.search.toLowerCase()) &&
            (!this.isShowOnlyMy || test.creatorId === this.currentUser.username)
        );

        if (!filteredConfigs.length) {
          return res;
        }

        return {
          ...res,
          [status]: filteredConfigs,
        };
      },
      {} as Record<ConfigsStatus, Array<ExternalTestModel>>
    );
  }

  showConfirmationDialog(test: ExternalTestModel) {
    this.isConfirmationDialogVisible = true;
    this.finishingTest = test;
  }

  async finishTest(activeTill: string) {
    await this.$store.dispatch("finishExternalTest", {
      id: this.finishingTest?.id,
      payload: { activeTill },
    });
    this.finishingTest = null;
    this.isConfirmationDialogVisible = false;
    this.$emit("update");
  }

  deleteExternalTest(value: ExternalTestModel) {
    this.$emit("delete", value);
  }
}
