var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-grow-1 flex-column fill-height",attrs:{"to":{
    name: 'external_test',
    params: {
      configId: _vm.test.id,
    },
  },"hover":"","outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.test.name)+" ")]),(_vm.menuItems.length)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1854471367)},[_c('v-list',_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.id,attrs:{"link":"","to":item.route || null},on:{"click":function($event){item.function() || null}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.creator"))+":")]),_vm._v(" "+_vm._s(_vm.test.creatorName)+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.testGroups"))+":")]),_vm._v(" "+_vm._s(_vm.test.testGroups.length - 1)+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("externalTest.externalTestId"))+":")]),_vm._v(" "+_vm._s(_vm.test.externalId)+" ")]),(_vm.test.abTestType)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("abTestConfig.abTestTypeTitle"))+":")]),_vm._v(" "+_vm._s(_vm.$lang(("abTestType." + (_vm.test.abTestType.toLowerCase()))))+" ")]):_vm._e()]),_c('test-result-icon',{attrs:{"status":_vm.test.status,"result-type":_vm.test.resultType}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }