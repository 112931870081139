



































































import { Component, Vue, Prop } from "vue-property-decorator";

import TestResultIcon from "@/ab-tests/components/TestResultIcon.vue";

import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";

@Component({ components: { TestResultIcon } })
export default class ExternalTestCard extends Vue {
  @Prop() test!: ExternalTestModel;

  get menuItems(): Array<Record<string, any>> {
    return [
      ...(this.test.status === ConfigsStatus.STARTED && this.test.hasEditAccess
        ? [
            {
              id: 1,
              function: this.finishTest,
              label: this.$lang("abTestConfig.actions.finish"),
            },
          ]
        : []),
      ...(this.test.deletable && this.test.hasDeleteAccess
        ? [
            {
              id: 2,
              function: this.deleteTest,
              label: this.$lang("externalTest.delete"),
            },
          ]
        : []),
    ];
  }

  finishTest() {
    this.$emit("finish", this.test);
  }

  deleteTest() {
    this.$emit("delete", this.test);
  }
}
