






































































import { Component, Vue, Prop } from "vue-property-decorator";

import ValidUtil from "@/shared/utils/ValidUtil";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";

@Component
export default class ExternalFinishConfirmDialog extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop() finishingTest!: ExternalTestModel;

  valid = false;
  menuActiveTill = false;
  activeTill = "";

  get finishingTestName(): string {
    return this.finishingTest?.name ?? "";
  }

  get isTestFinishing(): boolean {
    return this.$store.state.externalTestStore.isTestFinishing;
  }

  get minActiveTill(): string {
    return this.finishingTest?.activeSince ?? "";
  }

  get rulesActiveTill(): Array<any> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.minDate(
        this.finishingTest?.activeSince,
        this.$lang("validation.min", this.finishingTest?.activeSince ?? "")
      ),
    ];
  }

  emitFinishTest() {
    this.$emit("finishTest", this.activeTill);
  }
}
