


























































































































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Location } from "vue-router";

import TestInfo from "@/ab-tests/components/TestInfo.vue";
import ExternalTestList from "@/ab-tests/components/ExternalTestList.vue";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import {
  EXTERNAL_TEST_STATUSES,
  ExternalTestConfigModel,
  ExternalTestModel,
} from "@/ab-tests/models/ExternalTest";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import {
  AppSection,
  DictionaryType,
  ExternalTestDictionary,
} from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import { AbTestResultModel } from "@/ab-tests/models/AbTestConfigurationModel";

@Component({
  components: { ExternalTestList, TestInfo },
})
export default class ExternalTestsView extends mixins(AppSectionAccessMixin) {
  @Prop() checkedStatuses!: Array<ConfigsStatus>;
  @Prop() tab!: number;

  readonly externalTestStatuses = EXTERNAL_TEST_STATUSES;
  readonly DateUtil = DateUtil;
  readonly PAGE_SIZE = 16;
  search = "";
  searchProposals = "";
  isShowOnlyMy = false;
  currentProposalsPage = 1;
  isTestsResultsLoading = false;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  get stylesForVSheet(): string {
    return `
    border-bottom: thin solid ${
      this.dark ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.12)"
    } !important
    `;
  }

  get hasExternalConfigurationAccess(): boolean {
    return this.currentUser.viewAccessEntities[this.applicationId].includes(
      AppSection.EXTERNAL_TESTS_CONFIGURATION
    );
  }

  get externalConfigurationRoute(): Location {
    return {
      name: "external_configuration",
      params: {
        id: this.applicationId,
      },
    };
  }

  get currentTab(): number {
    return this.tab;
  }

  set currentTab(value) {
    this.$emit("update:tab", value);
  }

  get checkedExternalTestStatuses(): Array<ConfigsStatus> {
    return this.checkedStatuses;
  }

  set checkedExternalTestStatuses(statuses: Array<ConfigsStatus>) {
    this.$emit("update:checkedStatuses", statuses);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get applicationName(): string {
    return this.$store.getters.applicationsById.get(this.applicationId)?.name;
  }

  get externalTestConfig(): ExternalTestConfigModel {
    return this.$store.state.externalTestStore.externalTestConfig;
  }

  get isExternalTestConfigLoading(): boolean {
    return this.$store.state.externalTestStore.isExternalTestConfigLoading;
  }

  get proposals(): Array<ExternalTestDictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.EXTERNAL_TEST_PROPOSALS
    ].values
      .filter(
        ({ value, formattedStartDate }: ExternalTestDictionary) =>
          value.toLowerCase().includes(this.searchProposals.toLowerCase()) ||
          formattedStartDate
            .toLowerCase()
            .includes(this.searchProposals.toLowerCase())
      )
      .sort((itemA: ExternalTestDictionary, itemB: ExternalTestDictionary) => {
        if (itemA.startDate > itemB.startDate) {
          return -1;
        } else if (itemA.startDate < itemB.startDate) {
          return 1;
        }

        return 0;
      });
  }

  get paginatedProposals(): Array<ExternalTestDictionary> {
    const start = (this.currentProposalsPage - 1) * this.PAGE_SIZE;
    const end = this.currentProposalsPage * this.PAGE_SIZE;

    return this.proposals.slice(start, end);
  }

  get totalProposalsPages(): number {
    return Math.ceil(this.proposals.length / this.PAGE_SIZE);
  }

  get externalTestTotals(): Record<ConfigsStatus, number> | null {
    return this.$store.state.externalTestStore.externalTestTotals;
  }

  get externalTests(): Record<ConfigsStatus, Array<ExternalTestModel>> {
    return this.$store.state.externalTestStore.externalTests;
  }

  get isExternalTestsLoading(): boolean {
    return (
      this.$store.state.externalTestStore.isExternalTestsLoading ||
      this.isTestsResultsLoading
    );
  }

  @Watch("applicationId", { immediate: true })
  updateExternalTests() {
    this.$store.dispatch("getExternalTestTotals", this.externalTestStatuses);
    this.getExternalTests();

    if (
      this.externalTestConfig?.applicationId !== this.applicationId &&
      this.hasCreateAccess
    ) {
      this.$store.dispatch("getExternalTestConfig");
    }
  }

  @Watch("checkedExternalTestStatuses")
  watchCheckedStatus() {
    this.getExternalTests();
  }

  @Watch("searchProposals")
  watchSearchProposals() {
    this.currentProposalsPage = 1;
  }

  createExternalTest(proposal: ExternalTestDictionary) {
    this.$router.push({
      name: "external_new",
      params: {
        id: this.applicationId,
        externalId: proposal.value,
      },
    });
  }

  async getExternalTests() {
    await this.$store.dispatch(
      "getExternalTests",
      this.checkedExternalTestStatuses
    );

    if (!this.externalTests[ConfigsStatus.STATISTICS_RECALCULATED]?.length) {
      return;
    }

    this.isTestsResultsLoading = true;

    await this.$store
      .dispatch(
        "getAbTestResult",
        this.externalTests[ConfigsStatus.STATISTICS_RECALCULATED].map(
          ({ id }) => id
        )
      )
      .then((payload: Array<AbTestResultModel>) => {
        this.$store.commit(
          "setResultTypeForExternalTestById",
          AbTestResultModel.ofArray(payload)
        );
      });

    this.isTestsResultsLoading = false;
  }

  deleteExternalTest(value: ExternalTestModel) {
    this.$store.dispatch("deleteExternalTest", value);
  }
}
