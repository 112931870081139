var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(Object.values(_vm.sortedTests).flat().length)?[_vm._l((Object.entries(_vm.sortedTests)),function(ref,index){
var status = ref[0];
var tests = ref[1];
return [_c('v-row',{key:status + index,staticClass:"px-1"},[_c('v-col',{staticClass:"text-h6 font-weight-medium pb-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$lang(("abTest.status." + (status.toLowerCase()))))+" "),_c('span',{staticClass:"grey--text"},[_vm._v("• "+_vm._s(tests.length))])]),_vm._l((tests),function(test){return _c('v-col',{key:test.name,staticClass:"d-flex pa-2",attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"12"}},[_c('v-sheet',{staticClass:"fill-height",attrs:{"width":"100%","min-height":"150","color":"transparent"}},[_c('external-test-card',{attrs:{"test":test},on:{"finish":_vm.showConfirmationDialog,"delete":_vm.deleteExternalTest}})],1)],1)})],2),(index !== Object.entries(_vm.sortedTests).length - 1)?_c('v-divider',{key:status + 'divider',staticClass:"mb-4 mt-7"}):_vm._e()]})]:_c('v-col',{staticClass:"pa-0 text-center",style:([
      {
        color: _vm.$vuetify.theme.dark
          ? 'rgba(255, 255, 255, 0.5)'
          : 'rgba(0, 0, 0, 0.38)',
      } ]),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$lang("noDataText"))+" ")]),_c('external-finish-confirm-dialog',{attrs:{"finishing-test":_vm.finishingTest},on:{"finishTest":_vm.finishTest},model:{value:(_vm.isConfirmationDialogVisible),callback:function ($$v) {_vm.isConfirmationDialogVisible=$$v},expression:"isConfirmationDialogVisible"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }